<template>
  <!-- 登录页 -->
  <div class="login">
    <img src="../../assets/imgs/seller.jpg" width="100%" height="90%" />
    <div class="pagetitle">供应商管理端</div>
    <div class="foot-box mt20">
      <p>沪ICP备13021457号-5</p>
    </div>
    <div class="login-box">
      <div class="tabs">
        <span class="MANAGER" :class="loginType === 'SELL' ? 'login-active' : ''" @click="loginType = 'SELL'">管理员登录</span>
        <!-- <span class="STUDENT" :class="loginType === 'STUDENT' ? 'login-active' : ''" @click="loginType = 'STUDENT'">学生登录</span> -->
      </div>
      <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit">
        <a-form-item label="用户名">
          <a-input
            size="large"
            v-decorator="['username', { rules: [{ required: true, message: '请输入用户名' }] }]"
            placeholder="请输入用户名"
          >
            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item label="密码">
          <a-input
            size="large"
            v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
            type="password"
            placeholder="请输入密码"
          >
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <!-- <a-checkbox
            v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]"
          >记住密码</a-checkbox> -->
          <!-- <a class="login-form-forgot" href>忘记密码</a> -->
          <a-button type="primary" html-type="submit" :loading="loading" size="large" class="login-form-button">登 录</a-button>
          <div class="login-forget">
            <a-button type="link" class="forgetPwd" @click="forgetPwd('login')">手机号登录</a-button>
            <a-button type="link" class="forgetPwd" @click="forgetPwd('forget')">忘记密码</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <!-- 选择用户 -->
    <div class="aa" ref="aa">
      <a-modal
        width="40%"
        title="请选择用户进行登录"
        :visible="userVisible"
        @cancel="handleCancel"
        dialogClass="dialog"
        :footer="null"
        :getContainer="() => this.$refs.aa"
      >
        <a-spin tip="正在登录中..." :spinning="spinning">
          <ul class="oul">
            <li v-for="(item, index) in userList" :key="index" @click="getPhoneLogin(item.id)">
              <img src="../../assets/imgs/avatar.png" alt="" />
              <p>{{ item.nickname }}</p>
            </li>
          </ul>
        </a-spin>
      </a-modal>
    </div>
    <a-modal
      title="忘记密码"
      :visible="storeVisible"
      @ok="editNewPassword"
      @cancel="cancelStore"
      ok-text="确认"
      cancel-text="取消"
      width="450px"
      v-if="storeVisible"
    >
      <a-form-model :model="forgetPwdForm" ref="ruleForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="forgetPwdForm.phone" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <a-row :gutter="8">
            <a-col :span="12">
              <a-input placeholder="请输入验证码" v-model="forgetPwdForm.code" />
            </a-col>
            <a-col :span="12">
              <a-button @click="getCaptcha">{{ btnTitle }}</a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPwd" v-if="type === 'forget'">
          <a-input v-model="forgetPwdForm.newPwd" placeholder="请输入新密码" type="password" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="new" v-if="type === 'forget'">
          <a-input v-model="forgetPwdForm.new" placeholder="请输入新密码" type="password" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getMsgCode, codeChangePassword, getPhoneLogin } from '@/serve/auth'
import md5 from 'md5'
export default {
  name: 'login',
  data () {
    return {
      rules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        newPwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        new: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
      },
      userVisible: false,
      type: '',
      spinning: false,
      userList: [],
      isFlag: false,
      forgetPwdForm: {
        phone: '',
        code: '',
        newPwd: '',
        new: ''
      },
      timer: null,
      form: this.$form.createForm(this, { name: 'normal_login' }),
      loginType: 'SELL',
      loading: false,
      btnTitle: '获取验证码',
      storeVisible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    }
  },
  methods: {
    ...mapActions(['Login', 'GetInfo']),
    handleSubmit (e) {
      this.loading = true
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.password = md5(values.password)
          values.type = this.loginType
          this.Login(values)
            .then(() => {
              this.$router.push('/goodsList')
              this.loading = false
            })
            .catch(() => {
              this.$message.error('账号或密码错误')
              this.loading = false
            })
        }
      })
    },
    cancelStore () {
      this.storeVisible = false
      this.$refs.ruleForm.resetFields()
    },
    handleCancel () {
      this.userVisible = false
    },
    forgetPwd (flag) {
      this.storeVisible = true
      this.type = flag
    },
    // 手机号登录
    getPhoneLogin (userId = '') {
      let params = {
        clientType: 'SELL_CLIENT',
        code: this.forgetPwdForm.code,
        phone: this.forgetPwdForm.phone,
        userId
      }
      if (userId) {
        this.spinning = true
      }
      getPhoneLogin(params)
        .then(res => {
          // 若为true则绑定用户只有一个
          if (res.token) {
            this.GetInfo(res.token)
              .then(() => {
                this.spinning = false
                this.$router.push('/goodsList')
              })
              .catch(err => {
                this.spinning = false
                this.$message.error(err.data.message)
              })
          } else {
            this.userList = res.users
            this.userVisible = true
          }
          this.storeVisible = false
        })
        .catch(err => {
          this.$message.error(err.data.message)
        })
    },
    // 获取验证码
    getCaptcha () {
      if (!this.forgetPwdForm.phone.trim() || !/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.forgetPwdForm.phone)) {
        return this.$message.error('请输入合法手机号')
      }
      if (this.isFlag) return
      this.isFlag = true
      let time = 60
      let params
      if (this.type === 'login') {
        params = {
          phone: this.forgetPwdForm.phone,
          type: 'LOGIN_CONFIRM',
          roleType: 'SELL'
        }
      } else {
        params = {
          phone: this.forgetPwdForm.phone,
          type: 'CHANGE_PASSWORD',
          roleType: 'SELL'
        }
      }

      getMsgCode(params)
        .then(() => {
          this.timer = setInterval(() => {
            if (time > 0) {
              time--
              this.btnTitle = `${time}秒后重试`
            } else {
              this.btnTitle = '点击获取验证码'
              this.isFlag = false
              clearInterval(this.timer)
            }
          }, 1000)
        })
        .catch(err => {
          if ((err.status === 404 || err.status === 400) && !err.data.success) {
            this.$message.error(err.data.message)
          }
          this.btnTitle = '点击获取验证码'
          this.isFlag = false
          clearInterval(this.timer)
        })
    },
    editNewPassword (formName) {
      if (this.type === 'login') {
        if (!this.forgetPwdForm.phone || !this.forgetPwdForm.code) {
          return this.$message.error('请填写完整信息')
        }
        this.getPhoneLogin()
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (this.forgetPwdForm.newPwd !== this.forgetPwdForm.new && this.forgetPwdForm.newPwd) {
            this.forgetPwdForm.new = ''
            return this.$message.error('两次密码输入不一致,请重新输入')
          }
          if (this.forgetPwdForm.newPwd.length < 6) {
            return this.$message.error('密码至少需要6位字符')
          }
          if (valid) {
            let params = {
              code: this.forgetPwdForm.code,
              phone: this.forgetPwdForm.phone,
              newPwd: md5(this.forgetPwdForm.newPwd),
              roleType: 'SELL'
            }
            codeChangePassword(params)
              .then(() => {
                this.$message.success('重置密码成功！')
                this.storeVisible = false
                this.forgetPwdForm = {
                  phone: '',
                  code: '',
                  newPwd: '',
                  new: ''
                }
                this.btnTitle = '点击获取验证码'
                this.isFlag = false
                clearInterval(this.timer)
              })
              .catch(() => {
                this.btnTitle = '点击获取验证码'
                this.isFlag = false
                clearInterval(this.timer)
              })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  position: relative;
  height: 100%;
  // background-image: url("../../assets/imgs/bgImg.jpg");
  .forgetPwd {
    float: right;
    margin-top: 10px;
  }
  .pagetitle{
    font-size: 46px;
    color: #ffffff;
    position: fixed;
    right: 10%;
    top: 4%;
    width: 400px;
    text-align: center;
  }
  .dialog {
    .oul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0;
      flex-wrap: wrap;
      li {
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        padding: 10px;
        transition: all 0.2s ease-in;
        &:hover {
          border: 2px solid #fb921c;
          cursor: pointer;
          transform: scale(1.1);
        }
        p {
          margin: 10px auto;
        }
      }
    }
  }
  .tabs {
    height: 50px;
    background: #e8e8e8;
    border-radius: 5px 5px 0 0;
    color: #9e9e9e;

    .MANAGER {
      display: inline-block;
      text-align: center;
      // width: 50%;
      width: 100%;
      line-height: 50px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
    .login-active {
      background: #e8e8e8;
      color: #000;
      border-radius: 5px 5px 0px 0px;
    }
  }
  .login-box {
    position: absolute;
    right: 10%;
    top: 15%;
    width: 400px;
  }
  #components-form-demo-normal-login.login-form {
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px 30px;
    max-width: 400px;
    .login-forget {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: left;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;
    background: #1aa5ff;
    height: 50px;
    border: #1aa5ff;
    box-shadow: 0px 2px 11px #ccc;
  }
  .foot-box {
    background: #e8e8e8;
    padding: 20px 0;
    height: 10%;
    text-align: center;
  }
}</style
>>
